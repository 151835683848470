import React, { useEffect, useState } from 'react';
import { LegalPhaseMain } from './LegalPhase/LegalPhaseMain';
import {
  Alert,
  Badge,
  Box,
  Card,
  CardContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Tab,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DocumentOverview } from '../Document/DocumentOverview';
import { ILegalTransactionProgress } from '../../../Interfaces/ILegalTransactionProgress';
import { ILegalRole } from '../../../Interfaces/ILegalRole';
import { LegalTransactionDraftOverview } from './LegalTransactionDraftOverview';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IConfig } from '../../../Interfaces/IConfig';
import type { LegalTransactionWithMetadata } from './LegalTransactionOverview';
import {
  useCreateCustomId,
  useDeleteCustomId,
  useUpdateCustomId,
  useMetadata,
  useUpdateMetadata,
  useLegalTransactions,
} from '../../../hooks/data';
import type {
  ChangeNotification,
  ChangeType,
} from '../../../Interfaces/ILegalTransactionMetadata';
import { AddCustomIdDialog } from './AddCustomIdDialog';
import { LegalTransactionInvitePerson } from './LegalTransactionInvitePerson';
import { Delete, Add, Edit } from '@mui/icons-material';
import { EditCustomIdDialog } from './EditCustomIdDialog';
import { useAlert } from '../../../context/AlertContext';
import { DocumentOverviewMobile } from '../Document/DocumentOverviewMobile';

interface IProps {
  configObject: IConfig;
  legalTransaction: LegalTransactionWithMetadata;
  legalTransactionProgresses: ILegalTransactionProgress[];
  view?: string;
  handleClose: () => void;
}

export const LegalTransactionView: React.FC<IProps> = ({
  configObject,
  legalTransaction,
  legalTransactionProgresses,
  view,
  handleClose,
}) => {
  const { showAlert } = useAlert();
  const { refetchMetadata } = useMetadata();
  const { refetchLegalTransactions } = useLegalTransactions();
  const { updateMetadata } = useUpdateMetadata();
  const { createCustomId } = useCreateCustomId();
  const { updateCustomId } = useUpdateCustomId();
  const { deleteCustomId } = useDeleteCustomId();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const persistedCustomId = legalTransaction.customIds?.find(
    customId =>
      customId.idLegalTransaction === legalTransaction.idLegalTransaction &&
      customId.idContact === legalTransaction.idContact
  )?.customId;

  const [customId, setCustomId] = useState<string | undefined>(
    persistedCustomId
  );
  const [showAddCustomIdDialog, setShowAddCustomIdDialog] = useState(false);
  const [showEditCustomIdDialog, setShowEditCustomIdDialog] = useState(false);

  const currentPermission =
    legalTransaction.CurrentPermission ||
    ({
      idLegalRole: -1,
      idLegalTransaction: -1,
      LegalRole: 'Keine Rolle definier',
      viewDataCollection: true,
      viewDraft: true,
      viewDate: true,
      viewTransaction: true,
      viewInvoice: true,
      viewCertificate: true,
      canInvite: false,
      canUpload: true,
    } as ILegalRole);

  const [currentTab, setCurrentTab] = useState(
    view !== undefined
      ? view
      : !currentPermission.viewTransaction &&
          !currentPermission.viewInvoice &&
          !currentPermission.viewCertificate &&
          !currentPermission.viewDraft
        ? 'nopermission'
        : currentPermission.viewTransaction
          ? 'transaction'
          : currentPermission.viewCertificate
            ? 'certification'
            : currentPermission.viewInvoice
              ? 'invoice'
              : 'draft'
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);

    if (newValue === 'draft') {
      handleMarkAsRead(['draft', 'draft-message']);
    } else if (newValue === 'invoice') {
      handleMarkAsRead('invoice');
    } else if (newValue === 'certification') {
      handleMarkAsRead('certificate');
    } else if (newValue === 'transaction') {
      handleMarkAsRead('status-update');
    }
  };

  useEffect(() => {
    handleMarkAsRead('new-transaction');
  }, []);

  const unreadStatusTabNotificationsCount =
    legalTransaction.metadata?.changeNotifications.filter(
      notification =>
        notification.type === 'status-update' && !notification.viewed
    ).length;

  const unreadDraftsTabNotificationsCount =
    legalTransaction.metadata?.changeNotifications.filter(
      notifications =>
        (notifications.type === 'draft' ||
          notifications.type === 'draft-message') &&
        !notifications.viewed
    ).length;

  const unreadInvoicesTabNotificationsCount =
    legalTransaction.metadata?.changeNotifications.filter(
      notifications => notifications.type === 'invoice' && !notifications.viewed
    ).length;

  const unreadCertificatesTabNotificationsCount =
    legalTransaction.metadata?.changeNotifications.filter(
      notifications =>
        notifications.type === 'certificate' && !notifications.viewed
    ).length;

  const handleMarkAsRead = async (type: ChangeType | ChangeType[]) => {
    const metadata = legalTransaction.metadata;

    if (!metadata) return;

    let updatedNotifications: ChangeNotification[] = [];

    if (Array.isArray(type)) {
      const hasUnreadNotifications = metadata.changeNotifications.some(
        notification => type.includes(notification.type) && !notification.viewed
      );

      if (!hasUnreadNotifications) return;

      updatedNotifications = metadata.changeNotifications.map(notification =>
        type.includes(notification.type)
          ? { ...notification, viewed: true }
          : notification
      );
    } else {
      const hasUnreadNotifications = metadata.changeNotifications.some(
        notification => notification.type === type && !notification.viewed
      );

      if (!hasUnreadNotifications) return;

      updatedNotifications = metadata.changeNotifications.map(notification =>
        notification.type === type
          ? { ...notification, viewed: true }
          : notification
      );
    }

    try {
      await updateMetadata({
        legalTransactionId: legalTransaction.idLegalTransaction,
        changeNotifications: updatedNotifications,
      });

      refetchMetadata();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateCustomId = async (customId: string) => {
    try {
      await createCustomId({
        idLegalTransaction: legalTransaction.idLegalTransaction,
        idContact: legalTransaction.idContact,
        customId,
      });

      refetchLegalTransactions();

      showAlert({
        text: 'Kennung erfolgreich erstellt',
      });

      setCustomId(customId);

      setShowAddCustomIdDialog(false);
    } catch {
      showAlert({
        text: 'Fehler beim Erstellen der Kennung',
        severity: 'error',
      });
    }
  };

  const handleUpdateCustomId = async (customId: string) => {
    try {
      await updateCustomId({
        idLegalTransaction: legalTransaction.idLegalTransaction,
        idContact: legalTransaction.idContact,
        customId,
      });

      refetchLegalTransactions();

      showAlert({
        text: 'Kennung erfolgreich aktualisiert',
      });

      setCustomId(customId);
      setShowEditCustomIdDialog(false);
    } catch {
      showAlert({
        text: 'Fehler beim Aktualisieren der Kennung',
        severity: 'error',
      });
    }
  };

  const handleDeleteCustomId = async () => {
    try {
      await deleteCustomId({
        idLegalTransaction: legalTransaction.idLegalTransaction,
        idContact: legalTransaction.idContact,
      });

      refetchLegalTransactions();

      showAlert({
        text: 'Kennung erfolgreich gelöscht',
      });

      setCustomId(undefined);
    } catch {
      showAlert({
        text: 'Fehler beim Löschen der Kennung',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <Typography variant='h5' sx={{ mb: 3 }}>
        {legalTransaction.Title}
      </Typography>
      <Box sx={{ mt: 2 }} />
      <Typography variant='h6'>Grundinformationen</Typography>
      (Rechter Mausklick wird unterstützt)
      <Card variant='outlined'>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Art'
                value={legalTransaction.LegalTransactionType}
                size='small'
                variant='filled'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label='Bezeichnung'
                value={legalTransaction.Title}
                size='small'
                variant='filled'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              {legalTransaction.TransactionNumberArray.length === 0 ? (
                <TextField
                  label='Vorgangsnummer'
                  size='small'
                  variant='filled'
                  fullWidth
                  disabled
                />
              ) : (
                legalTransaction.TransactionNumberArray.map(x => (
                  <TextField
                    key={x.TransactionNumberEntry}
                    label='Vorgangsnummer'
                    value={x.TransactionNumberEntry}
                    size='small'
                    variant='filled'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ))
              )}
            </Grid>
            <Grid item xs={6} sm={3}>
              {legalTransaction.RecordNumberArray.length === 0 ? (
                <TextField
                  label='Urkundennummer'
                  size='small'
                  variant='filled'
                  fullWidth
                  disabled
                />
              ) : (
                legalTransaction.RecordNumberArray.map(x => (
                  <TextField
                    key={x.RecordNumberEntry}
                    label='Urkundennummer'
                    value={x.RecordNumberEntry}
                    size='small'
                    variant='filled'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ))
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label='Status'
                value={legalTransaction.LegalTransactionState}
                size='small'
                variant='filled'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {currentPermission.idLegalRole > 0 && (
              <>
                <Grid item xs={6}>
                  <TextField
                    label='Ihre Rolle'
                    value={currentPermission.LegalRole}
                    size='small'
                    variant='filled'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  {currentPermission.canInvite ? (
                    <LegalTransactionInvitePerson
                      currentPermission={currentPermission}
                      legalTransactionObject={legalTransaction}
                    />
                  ) : null}
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              {customId !== undefined ? (
                <TextField
                  label='Eigene Kennung'
                  value={customId}
                  onChange={e => setCustomId(e.target.value)}
                  fullWidth
                  size='small'
                  variant='filled'
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title='Kennung bearbeiten'>
                          <IconButton
                            size='small'
                            onClick={() => setShowEditCustomIdDialog(true)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Kennung löschen'>
                          <IconButton
                            size='small'
                            onClick={handleDeleteCustomId}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ),
                  }}
                />
              ) : (
                <Button
                  variant='contained'
                  onClick={() => setShowAddCustomIdDialog(true)}
                  startIcon={<Add />}
                >
                  Eigene Kennung hinzufügen
                </Button>
              )}
            </Grid>
          </Grid>

          <Box sx={{ mt: 5, height: isDesktop ? 30 : undefined, mb: 5 }}>
            <Stepper
              activeStep={legalTransaction.idLegalTransactionProgress}
              orientation={isDesktop ? 'horizontal' : 'vertical'}
              alternativeLabel={isDesktop}
            >
              {legalTransactionProgresses.map(x => (
                <Step
                  key={x.idLegalTransactionProgress}
                  completed={
                    Number(legalTransaction.idLegalTransactionProgress) >
                    x.idLegalTransactionProgress
                  }
                  active={
                    Number(legalTransaction.idLegalTransactionProgress) ===
                    x.idLegalTransactionProgress
                  }
                >
                  <StepLabel>{x.LegalTransactionProgress}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ mt: 10 }} />
      <Typography variant='h6'>Ergänzende Informationen</Typography>
      <Card variant='outlined'>
        <CardContent>
          <TabContext value={currentTab}>
            <Card variant='elevation'>
              <CardContent>
                <TabList
                  onChange={handleTabChange}
                  aria-label='basic tabs example'
                  variant='scrollable'
                  orientation={isDesktop ? 'horizontal' : 'vertical'}
                  indicatorColor='primary'
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {currentPermission.viewTransaction && (
                    <Tab
                      label={
                        unreadStatusTabNotificationsCount !== 0 ? (
                          <Badge
                            badgeContent={unreadStatusTabNotificationsCount}
                            color='primary'
                          >
                            Statusverlauf
                          </Badge>
                        ) : (
                          'Statusverlauf'
                        )
                      }
                      value='transaction'
                    />
                  )}
                  {currentPermission.viewDraft && (
                    <Tab
                      label={
                        unreadDraftsTabNotificationsCount !== 0 ? (
                          <Badge
                            badgeContent={unreadDraftsTabNotificationsCount}
                            color='primary'
                          >
                            Entwürfe
                          </Badge>
                        ) : (
                          'Entwürfe'
                        )
                      }
                      value='draft'
                    />
                  )}
                  {currentPermission.viewCertificate && (
                    <Tab
                      label={
                        unreadCertificatesTabNotificationsCount !== 0 ? (
                          <Badge
                            badgeContent={
                              unreadCertificatesTabNotificationsCount
                            }
                            color='primary'
                          >
                            Urkunden
                          </Badge>
                        ) : (
                          'Urkunden'
                        )
                      }
                      value='certification'
                    />
                  )}
                  {currentPermission.viewInvoice && (
                    <Tab
                      label={
                        unreadInvoicesTabNotificationsCount !== 0 ? (
                          <Badge
                            badgeContent={unreadInvoicesTabNotificationsCount}
                            color='primary'
                          >
                            Rechnungen
                          </Badge>
                        ) : (
                          'Rechnungen'
                        )
                      }
                      value='invoice'
                    />
                  )}
                </TabList>

              </CardContent>
            </Card>

            <TabPanel value='nopermission'>
              <Alert severity='warning'>
                Sie haben keine lesende Berechtigungen
              </Alert>
            </TabPanel>

            <TabPanel value='transaction'>
              {legalTransaction.LegalPhaseArray.length === 0 ? (
                <i>Derzeit steht kein Statusverlauf zur Verfügung.</i>
              ) : (
                <LegalPhaseMain
                  configObject={configObject}
                  legalTransaction={legalTransaction}
                  legalPhaseFullObjectArray={legalTransaction.LegalPhaseArray}
                />
              )}
            </TabPanel>

            <TabPanel value='draft'>
              <LegalTransactionDraftOverview
                configObject={configObject}
                legalTransactionObject={legalTransaction}
                draftArray={legalTransaction.DraftArray}
                draftMessageArray={legalTransaction.DraftMessageArray}
                setDraftMessageArray={() => {}}
              />
            </TabPanel>

            <TabPanel value='certification'>
              {isDesktop ?
                <DocumentOverview
                  key={`DocumentOverview-CertificateArray`}
                  configObject={configObject}
                  legalTransactionObject={legalTransaction}
                  idLegalRequirement={legalTransaction.idLegalTransaction}
                  documentArray={legalTransaction.CertificateArray}
                  setDocumentArray={() => {}}
                  allowRemove={false}
                  allowUpload={false}
                  title='Urkunden'
                  showReadPermission={false}
                  handleClose={handleClose}
                />
                :
                <DocumentOverviewMobile
                  key={`DocumentOverview-CertificateArray`}
                  configObject={configObject}
                  legalTransactionObject={legalTransaction}
                  documentArray={legalTransaction.CertificateArray}
                  title='Urkunden'
                  handleClose={handleClose}
                />
              }

            </TabPanel>

            <TabPanel value='invoice'>
            {isDesktop ?
              <DocumentOverview
                key={`DocumentOverview-InvoiceArray`}
                configObject={configObject}
                legalTransactionObject={legalTransaction}
                idLegalRequirement={legalTransaction.idLegalTransaction}
                documentArray={legalTransaction.InvoiceArray}
                setDocumentArray={() => {}}
                allowRemove={false}
                allowUpload={false}
                title='Rechnungen'
                showReadPermission={false}
                handleClose={handleClose}
              />
              :
              <DocumentOverviewMobile
                key={`DocumentOverview-InvoiceArray`}
                configObject={configObject}
                legalTransactionObject={legalTransaction}
                documentArray={legalTransaction.InvoiceArray}
                title='Rechnungen'
                handleClose={handleClose}
              />
            }
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
      {showAddCustomIdDialog && (
        <AddCustomIdDialog
          onClose={() => setShowAddCustomIdDialog(false)}
          onSave={handleCreateCustomId}
        />
      )}
      {showEditCustomIdDialog && (
        <EditCustomIdDialog
          onClose={() => setShowEditCustomIdDialog(false)}
          onSave={handleUpdateCustomId}
          initialCustomId={customId}
        />
      )}
    </>
  );
};
